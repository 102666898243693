<template>
  <div class="curator_apply_textarea">
    <el-input type="textarea" id="content" v-model="content" maxlength="1000"></el-input>
    <div class="curator_apply_upload_wrap">
      <div class="curator_apply_upload_text">
        <span>{{ $t('apply_curator_text1') }}</span>
      </div>
      <button class="curator_apply_upload_btn">
        <span class="upload_text">{{ $t('en_upload') }}</span>
        <span>+</span>
      </button>
    </div>
    <div class="curator_apply_file_wrap">
      <div class="curator_apply_file">
        <span>사업자등록증.pdf</span>
        <button></button>
      </div>
      <div class="curator_apply_file">
        <span>{{ $t('portfolio') }}.pdf</span>
        <button></button>
      </div>
    </div>
    <div class="curator_apply_terms_wrap">
<!--      <input type="checkbox" class="terms_check">-->
      <div class="terms_check" :class="{ active : isActive}" @click="isActive = !isActive"></div>
      <span class="terms_text" v-html="$t('apply_curator_text2')">
<!--        <span class="terms_text2">큐레이터 신청 규정</span>을 확인하였으며 동의합니다.-->
      </span>
    </div>
    <div class="curator_apply_btn_wrap">
      <button class="curator_btn cancel">{{ $t('cancel') }}</button>
      <button class="curator_btn apply">{{ $t('apply1') }}</button>
    </div>
  </div>
</template>

<script>

export default {
  name: "ItemWriteCurationLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      content:'',
      isActive: false,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
  },
  watch: {
  },
}
</script>

<style scoped>

</style>